import {Box, Button, CircularProgress, ClickAwayListener, IconButton, Tooltip} from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import firebase from "firebase/app";
import React, {useEffect, useState} from "react";

type ApiResponse = { response: string | null, error: boolean, loading: boolean, status: number | null};
type ApiRequest = {url: string, method: string, body: string};

export function useAsyncHttpCall(): [ApiResponse, (ApiRequest) => void] {
    const [data, setData] = useState<ApiResponse>({
        response: null,
        error: false,
        loading: false,
        status: null
    });
    const [request, setRequest] = useState<ApiRequest | null>(null);

    useEffect(() => {
        if (!request) {
            return;
        }
        setData({response: null, error: false, loading: true, status: null});

        const firebaseUser = firebase.auth().currentUser;
        if (firebaseUser) {
            // TODO: remove async await
            firebaseUser.getIdToken().then((jwt) =>
                fetch(process.env.REACT_APP_TURBOFLAGS_API + request.url, {
                    method: request.method, body: request.body, headers: new Headers({
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + jwt
                        }
                    )
                }))
                .then(async (response) => {
                    const data = await response.text()
                    setData({
                        response: data,
                        error: !response.ok,
                        loading: false,
                        status: response.status
                    })
                })
                .catch(() => {
                    setData({
                        response: null,
                        error: true,
                        loading: false,
                        status: null
                    })
                });
        } else {
            setData({
                response: "Not logged in",
                error: true,
                loading: false,
                status: 403
            });
        }
    }, [request, setData]);
    return [data, (r) => setRequest(r)];
}

export function useSyncHttpGet(url: string, deps: any[] = []) {
    const [data, setData] = useState({
        response: null,
        error: false,
        loading: true
    });
    // TODO remove async await if possible
    useEffect(() => {
        setData({
            response: null,
            error: false,
            loading: true
        });
        const firebaseUser = firebase.auth().currentUser;
        if (firebaseUser) {
            firebaseUser.getIdToken().then((jwt) =>
                fetch(process.env.REACT_APP_TURBOFLAGS_API + url, {
                    method: 'GET',
                    headers: new Headers({
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + jwt
                        })
                    }
                ))
                .then(async (response) => {
                    const data = await response.json();
                    setData({
                        response: data,
                        error: !response.ok,
                        loading: false
                    })
                })
                .catch((error) => {
                    setData({
                        response: null,
                        error: error,
                        loading: false
                    })
                })
        } else {
            setData({
                response: null,
                error: true,
                loading: false,
            });
        }
    // eslint-disable-next-line
    }, [url, ...deps]);
    return data;
}

export function HelpTooltip(props: {text: string}) {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    return  (
        <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
            <Tooltip title={props.text} open={tooltipOpen}>
                <IconButton size={'small'} onClick={() => setTooltipOpen(!tooltipOpen)}>
                    <HelpOutline/>
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    );
}

export function LoadingButton(props: { loading: boolean, onClick: () => void, children?: React.ReactNode }) {
    return (<Box sx={{position: 'relative'}}>
        <Button variant={"contained"} color={'primary'} size="medium" onClick={props.onClick} disabled={props.loading}>
            <Box sx={{visibility: props.loading ? 'hidden' : 'visible'}}>{props.children}</Box>
        </Button>
        {props.loading && (<CircularProgress size={24} sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginTop: '-12px',
            marginLeft: '-12px'
        }}/>)}
    </Box>)
}