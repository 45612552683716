import {Link as RouterLink, useHistory, useLocation, useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {HelpTooltip, LoadingButton, useAsyncHttpCall, useSyncHttpGet} from "./Infra";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Link,
    List,
    ListItem,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {FadingComponent} from "./App";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {parse as queryStringParse} from "query-string";
import {cleanupRule, RuleAttribute, RuleData, ruleIsInvalid, RuleJson, validateRule, ViewRule} from "./ViewRule";

type UserSegmentResponse = {id: number, accountId: number, name: string, json: string};
type UserSegmentJson = {include: RuleJson[], exclude: RuleJson[]};

function ViewUserSegments(props: {accountId: number, items: UserSegmentResponse[]}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const history = useHistory();
    const itemsList = props.items.map((segment) =>
        <ListItem key={segment.name}>
            <Link component={RouterLink} to={`/${props.accountId}/segments/${segment.id}`} variant={'body1'}>{segment.name}</Link>
        </ListItem>
    );
    const yupSchema = yup.object().shape({
        name: yup
            .string().required()
            .matches(/^[a-zA-z][a-zA-Z0-9_-]+$/, 'Invalid format')
            .test('exists', 'Not unique', (value) => props.items.findIndex((i) => i.name === value) === -1)
    });
    const { register, handleSubmit, errors } = useForm({resolver: yupResolver(yupSchema)});

    const handleDialogSubmit = (formData) => {
        history.push({
            pathname: `/${props.accountId}/segments/new`,
            search: '?name=' + encodeURIComponent(formData.name),
        });
    };
    return (
        <Stack spacing={2}>
            <Typography variant={'h5'}>User segments</Typography>
            <Paper sx={{width: {'sm': 400 }}} >
                <List>
                    {itemsList.length > 0 ? itemsList : <ListItem><Typography color="textSecondary" variant="subtitle2">no segment found</Typography></ListItem>}
                </List>
            </Paper>
            <Box>
                <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>New</Button>
            </Box>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <form onSubmit={handleSubmit(handleDialogSubmit)}>
                    <DialogTitle>User segment name</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Case sensitive. Names must be unique and start with a letter. Allowed characters: letters, digits, '_' and '-'.
                        </DialogContentText>
                        <TextField
                            name={'name'}
                            label={'Name'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            inputRef={register}
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color={"primary"} onClick={() => setDialogOpen(false)}>Cancel</Button>
                        <Button color={"primary"} type="submit">Ok</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Stack>
    );
}

export function AddUserSegment() {
    const {accountId} = useParams();
    const location = useLocation();
    const params = queryStringParse(location.search);
    const name = params.name;
    if (!name || typeof name !== "string") {
        return <Typography color={'error'} variant={'h5'}>Error: Name not provided</Typography>;
    }

    const fakeResponse: UserSegmentResponse = {
        accountId,
        id: -1,
        name,
        json: JSON.stringify({include: [], exclude: []}),
    };
    return <ViewSingleUserSegment response={fakeResponse} creationMode={true} />;
}

export function UserSegments() {
    const {accountId} = useParams();
    const {response, loading} = useSyncHttpGet(`/accounts/${accountId}/user-segments`)

    // TODO: handle errors
    if (loading) {
        return <CircularProgress/>;
    }
    const responseTyped = response as unknown as UserSegmentResponse[];
    return <ViewUserSegments accountId={accountId} items={responseTyped} />;
}

function ViewSegmentRuleCard(props: { rule: RuleData, onUpdate: (SegmentRuleData) => void, onDelete: () => void }) {
    return (
        <Paper>
            <Stack direction="row">
                <Box sx={{flexGrow: 1, p: 2}}>
                    <ViewRule excludedAttributes={[RuleAttribute.PercentageRollout, RuleAttribute.UserSegments]} {...props}/>
                </Box>
                <Box sx={{pt: 1}}>
                    <IconButton color="primary" size={'small'} onClick={props.onDelete}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            </Stack>
        </Paper>
    );
}

function ViewSingleUserSegment(props: {response: UserSegmentResponse, creationMode: boolean, createdDialog?: boolean}) {
    const userSegmentJson: UserSegmentJson = JSON.parse(props.response.json);
    const makeSegmentRuleData = (rule: RuleJson, idx: number) => ({
            ...rule,
            uiIdx: idx,
            missingOperator: false,
            missingAttribute: false,
            emptyString: false,
            emptyMultiString: false,
            outOfOrderRange: false,
        }
    );
    const [includeRules, setIncludeRules] = useState<RuleData[]>(userSegmentJson.include.map((rule, idx) => makeSegmentRuleData(rule, idx)));
    const [excludeRules, setExcludeRules] = useState<RuleData[]>(userSegmentJson.exclude.map((rule, idx) => makeSegmentRuleData(rule, idx)));
    const [apiResponse, sendApiRequest] = useAsyncHttpCall();
    const [showRecentlySaved, setRecentlySaved] = useState(false);
    const history = useHistory();
    const [createdDialogOpen, setCreatedDialogOpen] = useState(!!props.createdDialog);
    const maxRules = 5;

    useEffect(() => {
        if (!apiResponse.loading && apiResponse.status) {
            setRecentlySaved(true);
        }
    }, [setRecentlySaved, apiResponse]);

    if (props.creationMode) {
        if (!apiResponse.loading && apiResponse.status) {
            if (apiResponse.error) {
                return <Typography color="error">Error</Typography>;
            } else {
                const segmentId = JSON.parse(apiResponse.response as string).id;
                history.push({
                   pathname: `/${props.response.accountId}/segments/${segmentId}`,
                   search: '?creationSuccess=1',
                });
            }
        }
    }

    const updateIncludeAtIdx = (idx: number, newRule: RuleData) => {
        includeRules.splice(idx, 1, newRule);
        setIncludeRules([...includeRules]);
    };
    const updateExcludeAtIdx = (idx: number, newRule: RuleData) => {
        setExcludeRules(rules => {
            rules.splice(idx, 1, newRule);
            return [...rules];
        });
    };
    const handleCancel = () => {
        history.push(`/${props.response.accountId}/segments`);
    };
    const deleteIncludeAtIdx = (idx: number) => {
        includeRules.splice(idx, 1);
        setIncludeRules([...includeRules]);
    };
    const deleteExcludeAtIdx = (idx: number) => {
        setExcludeRules(rules => {
           rules.splice(idx, 1);
           return [...rules];
        });
    };

    const appendRule = (rules: RuleData[]) => (
        [
            ...rules,
            {
                uiIdx: Math.max(0, ...rules.map((r) => r.uiIdx)) + 1,
                attribute: undefined,
                operator: undefined,
                caseInsensitive: false,
                stringOperand: "",
                numericRangeStart: undefined,
                numericRangeEnd: undefined,
                multiStringOperand: undefined,
                missingAttribute: false,
                missingOperator: false,
                emptyMultiString: false,
                emptyString: false,
                outOfOrderRange: false,
            },
        ]
    );
    const addIncludedRule = () => {
        setIncludeRules(rules => appendRule(rules));
    };
    const addExcludeRule = () => {
      setExcludeRules(rules => appendRule(rules));
    };

    const includeItems = includeRules.map((rule, idx) => (
            <ViewSegmentRuleCard
                key={rule.uiIdx}
                rule={rule}
                onUpdate={(newRule) => updateIncludeAtIdx(idx, newRule) }
                onDelete={() => deleteIncludeAtIdx(idx)}
            />)
    );
    const excludeItems = excludeRules.map((rule, idx) => (
            <ViewSegmentRuleCard
                key={rule.uiIdx}
                rule={rule}
                onUpdate={(newRule) => updateExcludeAtIdx(idx, newRule) }
                onDelete={() => deleteExcludeAtIdx(idx)}
            />)
    );

    const validateMultipleRules = (rules) => rules.map(validateRule);

    const handleSave = () => {
        if (-1 === includeRules.findIndex(ruleIsInvalid) && -1 === excludeRules.findIndex(ruleIsInvalid)) {
            const combined = {
                include: includeRules.map(cleanupRule),
                exclude: excludeRules.map(cleanupRule)
            };
            if (props.creationMode) {
                sendApiRequest({
                    url: `/accounts/${props.response.accountId}/user-segments/`,
                    method: 'POST',
                    body: JSON.stringify({
                        name: props.response.name,
                        json: JSON.stringify(combined)
                    }),
                });

            } else {
                sendApiRequest({
                    url: `/accounts/${props.response.accountId}/user-segments/${props.response.id}`,
                    method: 'PUT',
                    body: JSON.stringify({json: JSON.stringify(combined)}),
                });
            }
        } else {
            setIncludeRules(rules => validateMultipleRules(rules));
            setExcludeRules(rules => validateMultipleRules(rules));
        }
    };

    const recentlySavedLabel = (
        <Typography color={'textSecondary'}>Recently saved.</Typography>
    );
    return (
        <Stack spacing={2} sx={{width: {'sm': 400}}} >
            <Stack direction={'row'} spacing={1}>
                <Typography variant={'h5'}>Segment</Typography>
                <Typography variant={'h5'} color={'primary'}>{props.response.name}</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
                <Typography variant={'h6'}>Include</Typography>
                <HelpTooltip text="Rule order is not important. Inclusion rules are applied first, then exclusion rules. See documentation for more details." />
            </Stack>
            {includeItems}
            <Box sx={{display: 'flex', justifyContent: "center"}} >
                <Button
                    variant={'contained'}
                    size="small"
                    onClick={addIncludedRule}
                    disabled={includeRules.length >= maxRules}
                >
                    Add
                </Button>
            </Box>
            <Typography variant={'h6'}>Exclude</Typography>
            {excludeItems}
            <Box sx={{display: 'flex', justifyContent: "center"}} >
                <Button
                    variant={'contained'}
                    size="small"
                    onClick={addExcludeRule}
                    disabled={excludeRules.length >= maxRules}
                >
                    Add
                </Button>
            </Box>
            <Stack direction="row" spacing={2} alignItems="center">
                <LoadingButton loading={apiResponse.loading} onClick={handleSave}>Save</LoadingButton>
                <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
            </Stack>
            <FadingComponent
                component={recentlySavedLabel}
                showComponent={showRecentlySaved}
                timeoutMs={5000}
                onHide={() => setRecentlySaved(false)}
            />
            <Dialog open={createdDialogOpen} onClose={() => setCreatedDialogOpen(false)}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <DialogContentText>User segment was created successfully</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color={'primary'} onClick={() => setCreatedDialogOpen(false)}>Ok</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}

export function EditUserSegment() {
    const location = useLocation();
    const { accountId, id } = useParams();
    const params = queryStringParse(location.search);
    const {response, loading} = useSyncHttpGet(`/accounts/${accountId}/user-segments/${id}`)

    // TODO handle failures
    if (loading) {
        return <CircularProgress/>;
    }

    // TODO: handle failures
    const typedResponse = response as unknown as UserSegmentResponse;
    return <ViewSingleUserSegment response={typedResponse} creationMode={false} createdDialog={!!params.creationSuccess}/>;
}