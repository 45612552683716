import {
    Alert,
    Box,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers";
import {useAsyncHttpCall} from "./Infra";
import {AccountApiResponse} from "./Account";
import {LoggedUserContext} from "./Auth";

function DeleteAdminDialog(props: {open: boolean, onConfirm: () => void, onClose: () => void, email: string}) {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Confirm</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Confirm removing <Box sx={{fontWeight: "bold"}} component="span">{" " + props.email + " "}</Box>
                    as an administrator.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {props.onConfirm(); props.onClose()}} >Confirm</Button>
                <Button onClick={props.onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

function AddAdminDialog(props: {open: boolean, onClose: () => void, onConfirm: () => void, getEmail: () => string, seatsLeft: number}) {
    const dialogContentText = props.seatsLeft <= 0 ?
        (<DialogContentText>You have no more seats left. Please upgrade your membership tier.</DialogContentText>) :
        (<DialogContentText>Confirm adding
            <Box component="span" sx={{fontWeight: "bold"}}>{" " + props.getEmail() + " "}</Box> as an administrator. You will have
            <Box component="span" sx={{fontWeight: "bold"}}>{" " + (props.seatsLeft - 1) + " "}</Box> seats left after this operation.
        </DialogContentText>);
    const seatsLeftActions = (<><Button onClick={() => {
        props.onConfirm();
        props.onClose()
    }}>Confirm</Button>
        <Button onClick={props.onClose}>Cancel</Button></>);
    const noSeatsLeftActions = <Button onClick={props.onClose}>Ok</Button>;
    const noSeatsTitle = <Box sx={{color: "error.main"}}>Error</Box>;
    return (
        <Dialog
            open={props.open}
            maxWidth="xs"
            onClose={props.onClose}
        >
            <DialogTitle>{props.seatsLeft <= 0 ? noSeatsTitle : "Confirm"}</DialogTitle>
            <DialogContent>
                {dialogContentText}
            </DialogContent>
            <DialogActions>
                {props.seatsLeft > 0 ? seatsLeftActions : noSeatsLeftActions}
            </DialogActions>
        </Dialog>
    );
}

export function AccountAdminsSection(props: {emails: string[], accountId: string}) {
    const [apiResponse, sendApiRequest] = useAsyncHttpCall();
    const items = apiResponse.response && !apiResponse.error ? (JSON.parse(apiResponse.response) as unknown as AccountApiResponse).emails : props.emails;
    const [addAdminDialog, setAddAdminDialog] = useState(false);
    const [adminToDelete, setAdminToDelete] = useState("");
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [snackBarOpen, setSnackbarOpen] = useState(false);
    const loggedUser = useContext(LoggedUserContext);

    useEffect(() => {
        if (apiResponse.status && !apiResponse.error) {
            setSnackbarOpen(true);
        }
    }, [apiResponse]);

    // TODO: this needs to come from the API
    const totalSeats = 5;
    const yupSchema = yup.object().shape({
        email: yup
            .string().email().required()
            .test('length', 'Exceeds maximum length of 50', (value) => value.length <= 50)
            .test('exists', 'Already exists', (value) => props.emails.findIndex((v) => v=== value) === -1)
    });
    const { register, handleSubmit, errors, reset, getValues } = useForm({resolver: yupResolver(yupSchema)});
    const handleDelete = (email: string) => {
        setAdminToDelete(email);
        setDeleteDialogOpen(true);
    };

    const handleAdd = () => {
        setAddAdminDialog(true);
    };
    const handleAdminAdd = () => {
        sendApiRequest({
            url: `/accounts/${props.accountId}/update-admins`,
            method: 'POST',
            body: JSON.stringify({
                add: [getValues("email")],
                remove: []
            })
        });
        reset();
    };
    const handleAdminRemove = () => {
        sendApiRequest({
            url: `/accounts/${props.accountId}/update-admins`,
            method: 'POST',
            body: JSON.stringify({
                add: [],
                remove: [adminToDelete]
            })
        });
    };

    const deleteAdminButton = (email: string) => {
        return email === loggedUser ? null : (<ListItemSecondaryAction>
            <IconButton
                edge={"end"}
                color="primary"
                aria-label={"delete"}
                onClick={() => handleDelete(email)}
                size="large">
                <DeleteIcon/>
            </IconButton>
        </ListItemSecondaryAction>);
    };
    const emailListItems = items.length > 0 ? (items.map((i) =>
            <ListItem key={i}>
                <ListItemText>{i}</ListItemText>
                {deleteAdminButton(i)}
            </ListItem>
        )
    ) : <ListItem><Typography color="textSecondary" variant="subtitle2">no administrator found</Typography></ListItem>;

    const adminsList = <List dense={true}>
        {emailListItems}
    </List>;
    return (<>
        <form onSubmit={handleSubmit(handleAdd)}>
            <Stack direction="row" spacing={2} alignItems="flex-start">
                <TextField
                    inputRef={register}
                    variant="standard"
                    label={"Email"}
                    name={"email"}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                />
                <Box pt={2}>
                    <Button size="small" variant={'contained'} type="submit">Add</Button>
                </Box>
            </Stack>
        </form>
        <Paper sx={{width: {'sm': 400}}}>
            {apiResponse.loading ? <Box p={2}><CircularProgress/></Box> : adminsList}
        </Paper>
        <AddAdminDialog
            open={addAdminDialog}
            onClose={() => setAddAdminDialog(false)}
            onConfirm={handleAdminAdd}
            getEmail={() => getValues("email")} seatsLeft={totalSeats - items.length}
        />
        <DeleteAdminDialog
            open={deleteDialogOpen}
            onConfirm={handleAdminRemove}
            onClose={() => setDeleteDialogOpen(false)}
            email={adminToDelete}
        />
        <Snackbar open={snackBarOpen} onClose={() => setSnackbarOpen(false)} autoHideDuration={3000}>
            <Alert severity="success">Success</Alert>
        </Snackbar>
    </>);
}