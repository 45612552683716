import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    FormLabel,
    IconButton,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link as RouterLink, useHistory, useLocation, useParams} from "react-router-dom";
import {FadingComponent, FeatureItemResponse} from "./App";
import {parse as queryStringParse} from "query-string";
import DeleteIcon from "@mui/icons-material/Delete";
import {HelpTooltip, LoadingButton, useAsyncHttpCall, useSyncHttpGet} from "./Infra";
import {ArrowDownward, ArrowUpward, Edit} from "@mui/icons-material";
import {useForm, Controller} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers";
import {cleanupRule, RuleData, ruleIsInvalid, RuleJson, validateRule, ViewRule} from "./ViewRule";

type FeatureJson = {
    name: string,
    variants: string[],
    defaultVariant: string,
    rules: FeatureRuleJson[]
};
type FeatureRuleJson = RuleJson & {variant: string};
type FeatureRuleData = RuleData & {uiIdx: number, variant: string, missingVariant: boolean | undefined};

function FeatureRuleCard(props: {rule: FeatureRuleData, values: string[], update: (newRule: FeatureRuleData) => void}) {
    const handleChangeVariant = (e) => {
        props.update({
            ...props.rule,
            variant: e.target.value as string,
            missingVariant: false
        } as FeatureRuleData)
    };
    return (
            <Stack spacing={1}>
                <ViewRule rule={props.rule} onUpdate={props.update} />
                <div>
                    <FormControl variant="standard" error={!!props.rule.missingVariant}>
                        <InputLabel shrink>Variant</InputLabel>
                        <Select displayEmpty={true} value={props.rule.variant} onChange={handleChangeVariant}>
                            {props.values.map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                        </Select>
                        {props.rule.missingVariant ? <FormHelperText>Missing</FormHelperText> : null}
                    </FormControl>
                </div>
            </Stack>);
}

function cleanupFeatureRule(ruleData: FeatureRuleData): FeatureRuleJson {
    return {
        ...cleanupRule(ruleData),
        variant: ruleData.variant
    }
}

function VariantsSection(props: {variants: string[], onDeleteVariant: (string) => void, onAddVariant: (string) => void}) {
    const yupSchema = yup.object().shape({
        name: yup
            .string()
            .matches(/^[a-zA-z][a-zA-Z0-9_-]+$/, 'Invalid format. See tooltip')
            .test('exists', 'Not unique', (value) => props.variants.findIndex((i) => i === value) === -1)
    });
    const { register, handleSubmit, errors, reset } = useForm({resolver: yupResolver(yupSchema)});
    const maxVariants = 5;

    const variantsItems = <>{props.variants.map((v) =>
        <ListItem dense button key={v}>
            <ListItemText>{v}</ListItemText>
            <ListItemSecondaryAction>
                <IconButton
                    edge={"end"}
                    aria-label={"delete"}
                    onClick={() => props.onDeleteVariant(v)}
                    size="large"
                    color="primary"
                >
                    <DeleteIcon/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )}</>;
    const variantFormatTooltipText = 'Case sensitive. Variant names must be unique and start with a letter. Letters, digits, "-" and "_" are allowed.';

    const variantForm = (
        <form onSubmit={handleSubmit((formData) => {
        props.onAddVariant(formData.name);
        reset();
    })}>

            <Stack direction={'row'} spacing={2}>
                    <TextField
                        inputRef={register}
                        variant="standard"
                        name={'name'}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />
                    <div>
                        <Button
                            variant={'contained'}
                            size="small"
                            type={'submit'}
                            disabled={props.variants.length >= maxVariants}
                        >
                            Add
                        </Button>
                    </div>
            </Stack>
        </form>);

    return  (<>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography variant={'h6'}>Variants</Typography>
                <HelpTooltip text={variantFormatTooltipText}/>
        </Stack>
        {variantForm}
        <Paper>
            <List>
                {props.variants.length === 0 ?
                    <ListItem dense><ListItemText>(empty)</ListItemText></ListItem> : variantsItems}
            </List>
        </Paper>
    </>);
}

function ViewSingleFeature(props: {feature: FeatureJson, featureId: number, accountId: number, creationMode: boolean, createdDialog: boolean}) {
    const [variants, setVariants] = useState<string[]>(props.feature.variants);
    const [rules, setRules] = useState<FeatureRuleData[]>(props.feature.rules.map((rule, idx) => ({uiIdx: idx, ...rule} as FeatureRuleData)));
    const [defaultVariant, setDefaultVariant] = useState<string>(props.feature.defaultVariant);
    const [missingDefault, setMissingDefault] = useState<boolean>(false);
    const [apiResponse, sendApiRequest] = useAsyncHttpCall();
    const history = useHistory();
    const [createdDialogOpen, setCreatedDialogOpen] = useState(props.createdDialog);
    const [showRecentlySaved, setRecentlySaved] = useState(false);
    const maxRules = 5;

    useEffect(() => {
        if (!apiResponse.loading && apiResponse.status) {
            setRecentlySaved(true);
        }
    }, [setRecentlySaved, apiResponse]);

    if (props.creationMode) {
        if (!apiResponse.loading && apiResponse.status) {
            if (apiResponse.error) {
                return <Typography color={'error'}>Error</Typography>;
            } else {
                const featureId = JSON.parse(apiResponse.response as string).id;
                history.push({
                    pathname: `/${props.accountId}/features/${featureId}`,
                    search: '?creationSuccess=1'
                });
            }
        }
    }

    const moveUp = (idx: number) => {
        if (idx > 0) {
            rules.splice(idx - 1, 2, rules[idx], rules[idx - 1])
            setRules([...rules]);
        }
    }
    const moveDown = (idx: number) => {
        if (idx < rules.length - 1) {
            rules.splice(idx, 2, rules[idx+1], rules[idx]);
            setRules([...rules]);
        }
    }
    const remove = (idx: number) => {
        rules.splice(idx, 1);
        setRules([...rules]);
    };

    const updateAtIdx = (idx: number, newRule: FeatureRuleData) => {
        rules.splice(idx, 1, newRule)
        setRules([...rules]);
    }

    const validateFeatureRule = (featureRule: FeatureRuleData) => {
        return {
            ...validateRule(featureRule),
            missingVariant: featureRule.variant === ''
        } as FeatureRuleData;
    }
    const featureRuleDataIsInvalid = (featureRule: FeatureRuleData) => {
        const validated = validateFeatureRule(featureRule);
        return ruleIsInvalid(featureRule) || validated.missingVariant;
    }

    const handleSave = () => {
        if (-1 === rules.findIndex(featureRuleDataIsInvalid) && defaultVariant) {
            const toSave: FeatureJson = {
                name: props.feature.name,
                variants: variants,
                defaultVariant: defaultVariant,
                rules: rules.map(cleanupFeatureRule)
            };
            if (props.creationMode) {
                sendApiRequest({
                    url: `/accounts/${props.accountId}/features`,
                    method: 'POST',
                    body: JSON.stringify({name: props.feature.name, rules: JSON.stringify(toSave)}),
                });
            } else {
                sendApiRequest({
                    url: `/accounts/${props.accountId}/features/${props.featureId}`,
                    method: 'PUT',
                    body: JSON.stringify({rules: JSON.stringify(toSave)}),
                });
            }
        } else {
            if (defaultVariant === '') {
                setMissingDefault(true);
            }
            setRules(rules => rules.map(validateFeatureRule));
        }
    }
    const handleDeleteVariant = (variantName) => {
        setRules(oldRules => oldRules.map((r) => r.variant === variantName ? {...r, variant: ''} : r));
        if (defaultVariant === variantName) {
            setDefaultVariant('');
        }
        setVariants(variants.filter((e) => e !== variantName));
    }

    const handleCancel = () => {
        history.push(`/${props.accountId}/features`)
    };
    const addRule = () => {
        setRules(
            [
                ...rules,
                {
                    uiIdx: Math.max(0, ...rules.map((r) => r[0])) + 1,
                    attribute: undefined,
                    operator: undefined,
                    caseInsensitive: false,
                    stringOperand: "",
                    numericRangeStart: undefined,
                    numericRangeEnd: undefined,
                    multiStringOperand: undefined,
                    missingAttribute: false,
                    missingOperator: false,
                    emptyMultiString: false,
                    emptyString: false,
                    outOfOrderRange: false,
                    variant: ""
                } as FeatureRuleData
            ]
        )
    }

    const addVariant = (name) => {
        setVariants([...variants, name]);
    };
    const recentlySavedLabel = (
        <Typography color={'textSecondary'}>Recently saved.</Typography>
    );
    const rulesTooltipText = ("Rules are evaluated in order of priorities. When a rule matches, the evaluation stops. " +
        "If no rule matches, then the default variant is returned. For more details see the documentation."
    );
    return (
        <Stack spacing={2} sx={{width: {'sm': 400}}} >
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography variant={'h5'}>Feature</Typography>
                <Typography variant={'h5'} color={'primary'}>{props.feature.name}</Typography>
                {props.creationMode ? <IconButton size={'small'}><Edit/></IconButton> : null}
            </Stack>
            <VariantsSection variants={variants} onDeleteVariant={handleDeleteVariant} onAddVariant={addVariant}/>
            <Stack pt={2} direction="row" spacing={1} alignItems="center">
                <Typography variant={'h6'}>Rules</Typography>
                <HelpTooltip text={rulesTooltipText}/>
            </Stack>

            {rules.map((r, idx) =>
                <Paper sx={{pl:2, pt: 1, pb: 2, pr: 2}} key={r.uiIdx}>
                    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1}>
                        <Box sx={{flexGrow: 1}} >
                            <Typography variant="overline">Priority {idx + 1}</Typography>
                            <FeatureRuleCard rule={r} values={variants} update={(newRule) => updateAtIdx(idx, newRule)}/>
                        </Box>
                        <ButtonGroup size={'small'} variant={'text'} orientation={"vertical"} color={"primary"} sx={{pl: 4}} >
                            <Button onClick={() => moveUp(idx)}>
                                <ArrowUpward/>
                            </Button>
                            <Button onClick={() => moveDown(idx)}>
                                <ArrowDownward/>
                            </Button>
                            <Button onClick={() => remove(idx)}>
                                <DeleteIcon/>
                            </Button>
                        </ButtonGroup>
                    </Stack>
                </Paper>)
            }
            <Box sx={{display: 'flex', justifyContent: 'center'}} >
                <Button
                    variant={'contained'}
                    size={'small'}
                    onClick={() => addRule()}
                    disabled={rules.length >= maxRules}
                >
                    Add rule
                </Button>
            </Box>
            <Stack alignItems={'center'} spacing={1} direction={'row'} justifyContent={'center'}>
                <FormLabel>Default variant</FormLabel>
                <FormControl error={missingDefault} variant="standard">
                    <Select value={defaultVariant} onChange={(e) => {
                        setDefaultVariant(e.target.value as string);
                        setMissingDefault(false);
                    }}>
                        {variants.map((v) =>
                            <MenuItem key={v} value={v}>{v}</MenuItem>
                        )}
                    </Select>
                    {missingDefault ? <FormHelperText>Missing</FormHelperText> : null}
                </FormControl>
            </Stack>

            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <LoadingButton loading={apiResponse.loading} onClick={() => handleSave()}>Save</LoadingButton>
                <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
            </Stack>
            <FadingComponent
                component={recentlySavedLabel}
                showComponent={showRecentlySaved}
                timeoutMs={5000}
                onHide={() => setRecentlySaved(false)}
            />
            <Dialog open={createdDialogOpen} onClose={() => setCreatedDialogOpen(false)}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <DialogContentText>Feature was created successfully</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color={'primary'} onClick={() => setCreatedDialogOpen(false)}>Ok</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}

export function EditFeature() {
    const location = useLocation();
    const { accountId, id } = useParams();
    const params = queryStringParse(location.search);

    const {response, loading, error} = useSyncHttpGet(`/accounts/${accountId}/features/${id}`)
    if (loading) {
        return <CircularProgress/>;
    }
    if (error) {
        return <Typography variant="h5" color={"error"}>Error</Typography>;
    }

    // TODO: handle failures
    const typedResponse = response as unknown as FeatureItemResponse;
    const typedFeature = JSON.parse(typedResponse.rules) as unknown as FeatureJson;
    return <ViewSingleFeature feature={typedFeature} featureId={id} accountId={1} creationMode={false} createdDialog={!!params.creationSuccess}/>;
}

export function AddFeature() {
    const {accountId} = useParams();
    const location = useLocation();
    const params = queryStringParse(location.search);
    const name = params.name;
    if (!name || typeof name !== "string") {
        return <Typography color={'error'} variant={'h5'}>Error: invalid name</Typography>;
    }
    const json = {
        name: name as string,
        variants: params.variants === "binary" ? ["on", "off"] : [],
        defaultVariant: '',
        rules: [],
    } as FeatureJson;

    return <ViewSingleFeature accountId={accountId} featureId={-1} creationMode={true} feature={json} createdDialog={false}/>;
}

function ViewFeaturesPage(props: {accountId: number, items: FeatureItemResponse[]}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const history = useHistory();
    const yupSchema = yup.object().shape({
        name: yup
            .string().required()
            .matches(/^[a-zA-z][a-zA-Z0-9_-]+$/, 'Invalid format')
            .test('exists', 'Not unique', (value) => props.items.findIndex((i) => i.name === value) === -1)
    });
    const { register, handleSubmit, errors, control } = useForm({
        resolver: yupResolver(yupSchema),
        defaultValues: {
            name: "",
            variants: "none"
        }
    });

    const handleDialogSubmit = (formData) => {
        const encodedName = encodeURIComponent(formData.name);
        history.push({pathname: `/${props.accountId}/features/new`, search: `?name=${encodedName}&variants=${formData.variants}`})
    };

    const featureList = props.items.map((f) =>
        <ListItem key={f.name}>
            <Link variant="body1" component={RouterLink} to={`/${props.accountId}/features/${f.id}`}>{f.name}</Link>
        </ListItem>
    );

    return (
        <Stack spacing={2}>
            <Typography variant={'h5'}>Feature Flags</Typography>
            <Paper sx={{width: {'sm': 400}}} >
                <List component={"nav"}>
                    {featureList.length > 0 ? featureList : <ListItem>
                        <Typography color="textSecondary" variant="subtitle2">no feature flag found</Typography>
                    </ListItem>}
                </List>
            </Paper>
            <div>
                <Button variant={'contained'} color={'primary'} onClick={() => setDialogOpen(true)}>New</Button>
            </div>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <form onSubmit={handleSubmit(handleDialogSubmit)}>
                    <DialogTitle>Feature name</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Case sensitive. Names must be unique and start with a letter. Allowed characters: letters, digits, '_' and '-'.
                        </DialogContentText>
                        <TextField
                            name={'name'}
                            label={'Name'}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            variant="standard"
                            inputRef={register}
                        />
                        <Box pt={2}>
                            <DialogContentText>Initial variants</DialogContentText>
                            <Controller
                                control={control}
                                name="variants"
                                as={
                                    <Select sx={{pl: 1}} variant="standard">
                                        <MenuItem value="binary">binary</MenuItem>
                                        <MenuItem value="none">none</MenuItem>
                                    </Select>
                                }
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button color={"primary"} onClick={() => setDialogOpen(false)}>Cancel</Button>
                        <Button color={"primary"} type="submit">Ok</Button>
                    </DialogActions>
                </form>
            </Dialog>
    </Stack>);
}

export function FeaturesPage() {
    const {accountId} = useParams();
    const {response, loading} = useSyncHttpGet(`/accounts/${accountId}/features`)

    // TODO: handle errors
    if (loading) {
        return <CircularProgress/>;
    }

    const responseTyped = response as unknown as FeatureItemResponse[];
    return <ViewFeaturesPage accountId={accountId} items={responseTyped}/>;
}

