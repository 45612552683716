import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import './App.css';
import {BrowserRouter, Link, Route, Switch, useHistory, useLocation} from "react-router-dom"

import {
    AppBar,
    Box,
    Button,
    Link as MuiLink,
    createTheme,
    CssBaseline,
    Drawer,
    Stack,
    ThemeProvider,
    Toolbar,
    Typography,
    useMediaQuery,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import {AddFeature, EditFeature, FeaturesPage} from "./Feature";
import {blueGrey, orange} from "@mui/material/colors";
import {AddUserSegment, EditUserSegment, UserSegments} from './UserSegments';
import {Account, AccountPicker} from './Account';
import {LoggedUserContext, LoggedUserProvider} from "./Auth";
import firebase from "./firebase";
import {StyledFirebaseAuth} from "react-firebaseui";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const theme = createTheme({
    palette: {
        primary: {
            main: '#007af3'
        },
        secondary: orange,
        background: {
            default: '#f2f2f2',
        },
    },
    typography: {
        fontFamily: "Poppins, sans-serif"
    }
});

class ErrorBoundary extends React.Component<{}, {hasError: boolean}> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export const CurrentAccountContext = React.createContext<number | null>(null);
export const CurrentAccountSetterContext = React.createContext<(id: number | undefined) => void>(
   () => {}
);

const CurrentAccountProvider: FunctionComponent<{}> = ({children}) => {
    const [accountId, setAccountId] = useState<number>();

    return (<CurrentAccountContext.Provider value={accountId || null}>
        <CurrentAccountSetterContext.Provider value={setAccountId}>
            {children}
        </CurrentAccountSetterContext.Provider>
    </CurrentAccountContext.Provider>);
}

function Pricing() {
    return <Typography variant={"h4"}>Pricing</Typography>;
}

function Home() {
    return (<>
        <Typography variant="h4">Turbo Flags</Typography>
        <Box mt={2}>
            <Typography>Welcome to Turbo Flags. Please sign in before continuing. </Typography>
            <Typography>This product is in beta, make sure you ask the admin for an account.</Typography>
        </Box>
    </>);
}

function LoggedInAccount() {
    return (
        <Stack spacing={2}>
            <Typography variant="h5">Welcome</Typography>
            <Typography>You can create feature flags and user segments using the menu.</Typography>
        </Stack>
    );
}

function Tos() {
    return <Typography variant='h4'>Terms of service.</Typography>
}

function PrivacyPolicy() {
    return <Typography variant='h4'>Privacy policy</Typography>
}

function SignIn() {
    const uiConfig =  {
        signInSuccessUrl: '/firebase-redirect',
        signInOptions: [
            {
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                // customParameters: {
                //     prompt: 'select_account'
                // }
            },
            // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.GithubAuthProvider.PROVIDER_ID,
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: '/tos',
        // Privacy policy url/callback.
        privacyPolicyUrl: '/privacy'
    };

    return (
        <ThemeProvider theme={theme} >
            <CssBaseline/>
            <Box component='main' sx={{marginTop: '8rem', textAlign: 'center'}}>
                <Typography variant='h4'>Sign In / Create Account</Typography>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
            </Box>
        </ThemeProvider>
    );
}

function LinkButton(props: {to: string, label: string}) {
    return <Button sx={{color: blueGrey[700]}} component={Link} to={props.to}>{props.label}</Button>;
}
const appBarHeight = 64;

function MainMenu(props: {mobileDrawerOpen: boolean, onMobileDrawerClose: () => void}) {
    const theme = useTheme();
    const location = useLocation();
    const isDesktopVersion = useMediaQuery(theme.breakpoints.up('lg'));
    const loggedUser = useContext(LoggedUserContext);
    const history = useHistory();
    const currentAccountIdSetter = useContext(CurrentAccountSetterContext);
    const currentAccountId = useContext(CurrentAccountContext);

    useEffect(() => {
        if (props.mobileDrawerOpen) {
            props.onMobileDrawerClose();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const accountMenu = currentAccountId ? (
        <>
            <LinkButton to={`/${currentAccountId}/account`} label={'Account'} />
            <LinkButton to={`/${currentAccountId}/segments`} label={'User segments'} />
            <LinkButton to={`/${currentAccountId}/features`} label={'Feature flags'} />
        </>
    ) : (
        <LinkButton to={'/accounts'} label={'Account'} />
    );

    const loggedInMenu = (<>
        {accountMenu}
        <Button sx={{color: blueGrey[700]}}
                onClick={() => {
                  firebase.auth().signOut();
                  history.push("/");
                  currentAccountIdSetter(undefined);
                }}
        >Sign out</Button>
    </>);

    const loggedOutMenu = (<>
        <LinkButton to={'/sign-in'} label={'Sign in'} />
        <LinkButton to={'/documentation'} label={'Documentation'} />
        <LinkButton to={'/pricing'} label={'Pricing'} />
    </>);

    // TODO: remove the box with minHeight on desktop
    const content = (<>
        {isDesktopVersion && <Box sx={{minHeight: appBarHeight}}/>}
        <Stack pt={2} pl={2} direction={'column'} alignItems={'flex-start'}>
            {loggedUser ? loggedInMenu : loggedOutMenu}
        </Stack>
    </>);

    const desktopDrawer = (<Drawer variant={'permanent'} sx={{
        width: '200px',
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
            width: '200px',
        },
    }}
    >
        {content}
    </Drawer>);
    const mobileDrawer = (
        <Drawer variant={"temporary"}
            open={props.mobileDrawerOpen}
            anchor={"left"}
            onClose={props.onMobileDrawerClose}
            sx={{
                width: '200px',
                [`& .MuiDrawer-paper`]: {
                    paddingTop: '3rem',
                    width: '200px'
                },
            }}
        >
            {content}
        </Drawer>);
    return isDesktopVersion ? desktopDrawer : mobileDrawer;
}

function RouteWithLayout(props: {path: string, children: React.ReactNode}) {
    const loggedUser = useContext(LoggedUserContext);
    const history = useHistory();

    if (!loggedUser) {
        history.push("/sign-in");
    }

    return (
        <Route path={props.path}>
            <MainLayout>
                {props.children}
            </MainLayout>
        </Route>
    );
}

function FirebaseRedirect() {
    const loggedUser = useContext(LoggedUserContext);
    const history = useHistory();

    if (loggedUser) {
        history.push("/accounts");
    }
    return <></>;
}

function MainRouter() {
    return (
        <BrowserRouter>
        <Switch>
            <RouteWithLayout path={"/accounts"}>
                <AccountPicker />
            </RouteWithLayout>
            <Route path={"/firebase-redirect"}>
                <FirebaseRedirect />
            </Route>
            <Route path={"/sign-in"}>
                <SignIn />
            </Route>
            <RouteWithLayout path={"/pricing"}>
                <Pricing />
            </RouteWithLayout>
            <RouteWithLayout path={"/:accountId/account"}>
                <Account />
            </RouteWithLayout>
            <RouteWithLayout path={"/:accountId/segments/new"}>
                <AddUserSegment />
            </RouteWithLayout>
            <RouteWithLayout path={"/:accountId/segments/:id"}>
                <EditUserSegment />
            </RouteWithLayout>
            <RouteWithLayout path={"/:accountId/segments"}>
                <UserSegments />
            </RouteWithLayout>
            <RouteWithLayout path={"/:accountId/features/new"}>
                <AddFeature />
            </RouteWithLayout>
            <RouteWithLayout path={"/:accountId/features/:id"}>
                <EditFeature />
            </RouteWithLayout>
            <RouteWithLayout path={"/:accountId/features"}>
                <FeaturesPage />
            </RouteWithLayout>
            <RouteWithLayout path={"/documentation"}>
                <Documentation />
            </RouteWithLayout>
            <RouteWithLayout path={"/tos"}>
                <Tos />
            </RouteWithLayout>
            <RouteWithLayout path={"/privacy"}>
                <PrivacyPolicy />
            </RouteWithLayout>
            <RouteWithLayout path={"/:accountId"}>
                <LoggedInAccount />
            </RouteWithLayout>
            <RouteWithLayout path={"/"}>
                <Home />
            </RouteWithLayout>
    </Switch>
    </BrowserRouter>
    )
}

// TODO: change the title of browser depending on the page
// TODO: set favicon
// TODO: don't show account picker on page reloads
function MainLayout(props: {children: React.ReactNode}) {
    const isDesktopVersion = useMediaQuery(theme.breakpoints.up('lg'));
    const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false);
    const currentAccountId = useContext(CurrentAccountContext);
    const hamburger = isDesktopVersion ? null : <MenuIcon htmlColor='black' onClick={() => setMobileDrawerOpen(true)}/>;
    const toolbarVSpace = <Box sx={{minHeight: appBarHeight}} />;
    const logoStyle = {fontSize: "1.2rem", fontWeight: "bold"};

    return (
        <ThemeProvider theme={theme}>
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" sx={{
                ...(isDesktopVersion && {zIndex: (theme) => theme.zIndex.drawer + 1}),
                background: "white",
            }}
            >
                <Toolbar>
                    {hamburger}
                    <MuiLink component={Link} to={`/${currentAccountId}`} sx={{textDecoration: "none"}}>
                        <Typography component={"span"} ml={.5} sx={{color: "black", ...logoStyle}}>Turbo</Typography>
                        <Typography component={"span"} color="primary" sx={{...logoStyle}}>Flags</Typography>
                    </MuiLink>
                </Toolbar>
            </AppBar>
            <MainMenu mobileDrawerOpen={isMobileDrawerOpen}
                      onMobileDrawerClose={() => setMobileDrawerOpen(false)}
            />
            <Box component={'main'}>
                {toolbarVSpace}
                <Box p={4} >
                    <ErrorBoundary>
                        {props.children}
                    </ErrorBoundary>
                </Box>
            </Box>
        </Box>
    </ThemeProvider>);
}

function App() {
    return (
        <LoggedUserProvider>
            <CurrentAccountProvider>
                    <MainRouter/>
            </CurrentAccountProvider>
        </LoggedUserProvider>
    );
}

export type FeatureItemResponse = {id: number, name: string, rules: string};

export function FadingComponent(props: {component: React.ReactNode, showComponent: boolean, onHide: () => void, timeoutMs: number}) {
    const {showComponent, onHide, timeoutMs} = props;
    useEffect(() => {
        if (showComponent) {
            const timer = setTimeout(() => onHide(), timeoutMs);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [showComponent, onHide, timeoutMs]);

    return <>{props.showComponent ? props.component : null}</>;
}

function Documentation() {
    return (
        <Typography variant="h5">Documentation</Typography>
    );
}

export default App;
