import React, {useContext, useEffect, useState} from "react";
import {HelpTooltip, useAsyncHttpCall, useSyncHttpGet} from "./Infra";
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Grid,
    Radio, RadioGroup, Stack,
    TextField,
    Typography
} from "@mui/material";
import {FileCopyOutlined} from "@mui/icons-material";
import {AccountAdminsSection} from "./AccountAdminsSection";
import {useParams, useHistory} from "react-router-dom";
import {LoggedUserContext} from "./Auth";
import {CurrentAccountSetterContext} from "./App";
import * as yup from "yup";
import firebase from "firebase";
import {yupResolver} from "@hookform/resolvers";
import {useForm} from "react-hook-form";

export type AccountApiResponse = {id: number, name: string, clientKey: string, emails: string[] };

function NoAccountFound() {
    const yupSchema = yup.object().shape({
        name : yup.string()
            .required()
            .matches(/^[a-zA-z][a-zA-Z0-9 -]+$/, 'Invalid format')
            .max(20, 'Must be at most 20 characters long')

    });
    const {register, handleSubmit, errors} = useForm({resolver: yupResolver((yupSchema))});
    const history = useHistory();
    const currentAccountSetter = useContext(CurrentAccountSetterContext);

    const [apiResponse, sendApiRequest] = useAsyncHttpCall();

    if (apiResponse.loading) {
        return <CircularProgress/>;
    }
    if (!apiResponse.loading && apiResponse.status) {
        if (apiResponse.error) {
            return <Typography color="error">Error {apiResponse.status}</Typography>;
        } else {
            const response: AccountApiResponse = JSON.parse(apiResponse.response as string);
            currentAccountSetter(response.id);
            history.push({
                pathname: `/${response.id}`,
                search: '?created=1',
            });
        }
    }

    const formSubmit = (formData) => {
        sendApiRequest({
            url: '/accounts',
            method: 'POST',
            body: JSON.stringify({
                name: formData.name
            })
        });
    };

    return (
        <form onSubmit={handleSubmit(formSubmit)}>
            <Stack spacing={2}>
                <Typography variant="h4">Create Account</Typography>
                <Typography>
                    No account was found for email address <strong>{firebase.auth().currentUser?.email}</strong>. You can create one using the form below.
                </Typography>
                    <Stack direction="row">
                        <Typography variant="h5">Account name</Typography>
                        <HelpTooltip text={"Must start with a letter. Allowed characters: letters, digits, space, dash"} />
                    </Stack>
                    <TextField
                        inputRef={register}
                        label="Name"
                        name="name"
                        variant="standard"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        sx={{maxWidth: '300px'}} />
                    <div>
                        <Button type="submit" variant="outlined">Create</Button>
                    </div>
            </Stack>
        </form>
    );
}

export function AccountPicker() {
    const loggedUser = useContext(LoggedUserContext);
    const {response, loading} = useSyncHttpGet("/accounts", [loggedUser])
    const currentAccountSetter = useContext(CurrentAccountSetterContext);
    const history = useHistory();
    const [selectedAccount, setSelectedAccount] = useState<string>();
    if (loading) {
        return <CircularProgress/>;
    }
    const typedResponse: AccountApiResponse[] = (response as unknown as AccountApiResponse[] )
    if (!typedResponse || typedResponse.length === 0) {
        return <NoAccountFound />;
    }

    if (typedResponse.length === 1) {
        const accountId = typedResponse[0].id;
        currentAccountSetter(accountId);
        history.push(`/${accountId}`);
    }

    let radioOptions = typedResponse && typedResponse.map((account) => (
        <FormControlLabel key={account.id} value={account.id.toString()} control={<Radio/>} label={account.name}/>
    ));

    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid item>
                <Typography variant="h4">Accounts</Typography>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1">Select the account you want to use.</Typography>
            </Grid>
            <Grid item>
                <Box pl={2}>
                    <RadioGroup aria-label="gender" name="gender1" value={selectedAccount || null} onChange={(event) => {
                        setSelectedAccount(event.target.value);
                    }}>
                        {radioOptions}
                    </RadioGroup>
                </Box>
            </Grid>
            <Grid item>
                <Button variant="outlined" color="primary" onClick={() => {
                    if (selectedAccount) {
                        currentAccountSetter(parseInt(selectedAccount));
                        history.push(`/${selectedAccount}`);
                    }
                }}>Submit</Button>
            </Grid>
        </Grid>
    );
}

export function Account() {
    const {accountId} = useParams();
    const {response, error, loading} = useSyncHttpGet(`/accounts/${accountId}`);
    const [clientKeyCopied, setClientKeyCopied] = useState(false);

    useEffect(() => {
        if (clientKeyCopied) {
            const timer = setTimeout(() => setClientKeyCopied(false), 10000);
            return () => clearTimeout(timer);
        }
    }, [clientKeyCopied]);

    if (loading) {
        return <CircularProgress/>;
    }
    // TODO: figure out if I should render errors differently depending on HTTP status
    if (error) {
        return <Typography variant={'h5'}>Error</Typography>;
    }
    const accountDao = response as unknown as AccountApiResponse;
    // TODO test this with different browsers
    const handleCopyClientKey = () => {
        navigator.clipboard.writeText(accountDao.clientKey);
        setClientKeyCopied(true);
    };

    return (
        <Stack spacing={2}>
            <Typography variant={'h5'}>Account</Typography>
            <Typography variant={'h6'}>Client key</Typography>
            <Stack direction="row" spacing={2}>
                <TextField
                    multiline
                    disabled
                    defaultValue={accountDao.clientKey}
                    helperText={clientKeyCopied ? "Copied to clipboard" : " "}
                />
                <div>
                    <Button
                        onClick={handleCopyClientKey}
                        startIcon={<FileCopyOutlined/>}
                        variant={'contained'}
                        size="small"
                    >
                        Copy
                    </Button>
                </div>
            </Stack>
            <Typography variant={'h6'}>Manage administrators</Typography>
            <AccountAdminsSection emails={accountDao.emails} accountId={accountId} />
        </Stack>
    );
}