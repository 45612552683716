import React, {FunctionComponent, useEffect, useState} from 'react';
import firebase from "./firebase";

export const LoggedUserContext = React.createContext<string | null>(null);

export const LoggedUserProvider: FunctionComponent<{}> = ({children}) => {
    const [user, setUser] = useState<string | null>();
    useEffect(() => {
        const unsuscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUser(user.email);
            } else {
                setUser(null);
            }
        });
        return () => unsuscribe();
    }, [setUser]);

    return (<LoggedUserContext.Provider value={user || null}>
        {children}
    </LoggedUserContext.Provider>);
}