import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, {useContext} from "react";
import {CurrentAccountContext} from "./App";
import {useSyncHttpGet} from "./Infra";

export enum RuleAttribute {
    UserId = "USER_ID",
    Email = "EMAIL",
    UserSegments = "USER_SEGMENTS",
    FirstName = "FIRST_NAME",
    LastName = "LAST_NAME",
    PercentageRollout = "PERCENTAGE_ROLLOUT"
}

const ruleAttributeLabel: Record<RuleAttribute, string> = {
    [RuleAttribute.UserId]: "User Id",
    [RuleAttribute.Email]: "Email",
    [RuleAttribute.UserSegments]: "User Segments",
    [RuleAttribute.FirstName]: "First Name",
    [RuleAttribute.LastName]: "Last Name",
    [RuleAttribute.PercentageRollout]: "Percentage Rollout"
};

enum RuleOperator {
    NumericRange= "NUMERIC_RANGE",
    OneOf = "ONE_OF",
    ContainsAny = "CONTAINS",
    Matches = "MATCHES",
    StartsWith = "STARTS_WITH",
    EndsWith = "ENDS_WITH",
    BelongsToAny = "BELONGS_TO_ANY"
}

export type RuleJson = {
    attribute: RuleAttribute | undefined,
    operator: RuleOperator | undefined,
    caseInsensitive: boolean,
    stringOperand: string,
    numericRangeStart: number | undefined,
    numericRangeEnd: number | undefined,
    multiStringOperand: string[] | undefined,
};

export type RuleData = RuleJson & {
    uiIdx: number,
    missingAttribute: boolean,
    missingOperator: boolean,
    emptyMultiString: boolean,
    emptyString: boolean,
    outOfOrderRange: boolean,
};

const operatorLabels = {
    [RuleOperator.NumericRange]: "In numeric range",
    [RuleOperator.OneOf]: "One of",
    [RuleOperator.ContainsAny]: "Contains any of",
    [RuleOperator.Matches]: "Matches",
    [RuleOperator.StartsWith]: "Starts with",
    [RuleOperator.EndsWith]: "Ends with",
    [RuleOperator.BelongsToAny]: "User belongs to any"
};

const operatorsWithChips = {
    [RuleOperator.OneOf]: 1,
    [RuleOperator.ContainsAny]: 1
};

const operatorsWithCheckbox = {
    [RuleOperator.OneOf]: 1,
    [RuleOperator.ContainsAny]: 1,
    [RuleOperator.StartsWith]: 1,
    [RuleOperator.EndsWith]: 1,
    [RuleOperator.Matches]: 1,
};

const operatorsWithString = {
    [RuleOperator.StartsWith]: 1,
    [RuleOperator.EndsWith]: 1,
    // Re2 needs to be handled differently
    [RuleOperator.Matches]: 1,
};

function getNumericRangeStart(ruleData: RuleData) {
    return ruleData.numericRangeStart || 0;
}

function getNumericRangeEnd(ruleData: RuleData) {
    if (ruleData.numericRangeEnd === undefined) {
        return ruleData.attribute === RuleAttribute.PercentageRollout ? 100 : 0;
    } else {
        return ruleData.numericRangeEnd;
    }
}

// TODO: be smarter here
export function cleanupRule(rule: RuleData): RuleJson {
    return {
        attribute: rule.attribute,
        operator: rule.operator,
        caseInsensitive: rule.caseInsensitive,
        stringOperand: rule.stringOperand,
        numericRangeStart: getNumericRangeStart(rule),
        numericRangeEnd: getNumericRangeEnd(rule),
        multiStringOperand: rule.multiStringOperand
    };
}

export function validateRule(rule: RuleData): RuleData {
    return {
        ...rule,
        missingAttribute: rule.attribute === undefined,
        missingOperator: rule.operator === undefined && rule.attribute !== RuleAttribute.PercentageRollout,
        emptyMultiString: !!invalidEmptyMultistring(rule),
        emptyString: !!rule.operator && rule.operator in operatorsWithString && !rule.stringOperand,
        outOfOrderRange: ((rule.operator === RuleOperator.NumericRange || rule.attribute === RuleAttribute.PercentageRollout) && getNumericRangeStart(rule) >=
            getNumericRangeEnd(rule))
    };
}

export function ruleIsInvalid(rule: RuleData): boolean {
    const validated = validateRule(rule);
    return (validated.missingAttribute || validated.missingOperator || validated.emptyString ||
        validated.emptyMultiString || validated.outOfOrderRange);
}

function getOperatorOptionsForAttribute(attribute: RuleAttribute): RuleOperator[] {
    if (attribute === RuleAttribute.UserId) {
        return [RuleOperator.OneOf, RuleOperator.NumericRange]
    }
    if (attribute === RuleAttribute.Email || attribute === RuleAttribute.FirstName || attribute === RuleAttribute.LastName) {
        return [RuleOperator.OneOf, RuleOperator.StartsWith, RuleOperator.EndsWith]
    }
    if (attribute === RuleAttribute.UserSegments) {
        return [RuleOperator.BelongsToAny];
    }
    return [];
}

function invalidEmptyMultistring(rule: RuleData) {
    return rule.operator && rule.operator in operatorsWithChips && !(rule.multiStringOperand && rule.multiStringOperand.length > 0)
}

function NumericRangeOperand(props: { rule: RuleData, onUpdate: (SegmentRuleData) => void }) {
    const handleNumericStartChange = (e) => {
        props.onUpdate({
            ...props.rule,
            numericRangeStart: e.target.value as number,
            outOfOrderRange: false,
        });
    };
    const handleNumericEndChange = (e) => {
        props.onUpdate({
            ...props.rule,
            numericRangeEnd: e.target.value as number,
            outOfOrderRange: false,
        });
    };

    return (<>
        <Stack direction={'row'} alignItems="center" spacing={2}>
                <TextField
                    type={'number'}
                    variant="standard"
                    label={'Start'}
                    error={props.rule.outOfOrderRange}
                    helperText={'inclusive'}
                    value={props.rule.numericRangeStart || 0}
                    onChange={handleNumericStartChange}/>
                <TextField
                    type={'number'}
                    variant="standard"
                    error={props.rule.outOfOrderRange}
                    label={'End'}
                    helperText={'exclusive'}
                    value={props.rule.numericRangeEnd || 0}
                    onChange={handleNumericEndChange}/>
        </Stack>
        {props.rule.outOfOrderRange ?
            (<Typography color={'error'}>Start must be less than end.</Typography>)
            : null}
    </>);
}

// TODO proper validations
function ChipOperand(props: { rule: RuleData, onUpdate: (SegmentRuleData) => void }) {
    // TODO: make sure it's a valid email
    const handleChipChange = (newChips: string[]) => {
        props.onUpdate({
            ...props.rule,
            multiStringOperand: newChips,
            emptyMultiString: false,
        });
    };

    return (<Autocomplete
            multiple
            freeSolo
            disableClearable
            value={props.rule.multiStringOperand || []}
            options={[]}
            onChange={(event, newValue) => handleChipChange(newValue as string[])}
            renderInput={(params) => (
                <TextField {...params} variant="standard"  />
            )}
        />);
}


function TextOperand(props: { rule: RuleData, onUpdate: (SegmentRuleData) => void }) {
    const handleStringOperandChange = (e) => {
        props.onUpdate({
            ...props.rule,
            stringOperand: e.target.value as string,
            emptyString: false,
        });
    };

    return(<TextField
            value={props.rule.stringOperand || ""}
            variant="standard"
            onChange={handleStringOperandChange}
            error={props.rule.emptyString}
            helperText={props.rule.emptyString ? "Cannot be empty" : ""}
        />);
}

function CheckboxOperand(props: { rule: RuleData, onUpdate: (SegmentRuleData) => void }) {
    const handleCheckboxChange = (e) => {
        props.onUpdate({...props.rule, caseInsensitive: e.target.checked});
    };

    return (<Box>
        <FormControlLabel
            control={<Checkbox checked={props.rule.caseInsensitive || false} onChange={handleCheckboxChange}/>}
            label="Case insensitive"
        />
    </Box>);
}

function UserSegmentOperand(props: {rule: RuleData, onUpdate: (SegmentRuleData) => void}) {
    const currentAccountId = useContext(CurrentAccountContext);
    const {response, loading} = useSyncHttpGet(`/accounts/${currentAccountId}/user-segments/names`);

    const handleChange = (options: string[]) => {
        props.onUpdate({...props.rule, multiStringOperand: options});
    }
    const selectedOptions = new Set(props.rule.multiStringOperand);

    return (
        <Autocomplete
            multiple
            loading={loading}
            disableClearable
            getOptionDisabled={(option) => selectedOptions.has(option)}
            value={props.rule.multiStringOperand || []}
            options={response ? response as unknown as string[] : []}
            onChange={(event, newValue) => handleChange(newValue as string[])}
            renderInput={(params) => (
                <TextField {...params} variant="standard"  />
            )}
        />
    );
}

function PercentageRolloutOperand(props: { rule: RuleData, onUpdate: (SegmentRuleData) => void }) {
    const handleNumericStartChange = (e) => {
        props.onUpdate({
            ...props.rule,
            numericRangeStart: Math.round(parseFloat(e.target.value) * 10),
            outOfOrderRange: false,
        });
    };
    const handleNumericEndChange = (e) => {
        props.onUpdate({
            ...props.rule,
            numericRangeEnd: Math.round(parseFloat(e.target.value) * 10),
            outOfOrderRange: false,
        });
    };

    return (<>
        <Stack direction={'row'} spacing={2}>
                    <TextField
                        type={'number'}
                        label={'Start'}
                        variant="standard"
                        inputProps={{min: 0, max: 100, type: 'number', step: 0.1}}
                        InputProps={{endAdornment: '%'}}
                        error={props.rule.outOfOrderRange}
                        helperText={'inclusive'}
                        value={(props.rule.numericRangeStart || 0) / 10}
                        onChange={handleNumericStartChange}
                    />
                    <TextField
                        type={'number'}
                        variant="standard"
                        inputProps={{min: 0, max: 100, type: 'number', step: 0.1}}
                        InputProps={{endAdornment: '%'}}
                        error={props.rule.outOfOrderRange}
                        label={'End'}
                        helperText={'exclusive'}
                        value={(props.rule.numericRangeEnd || 100) / 10}
                        onChange={handleNumericEndChange}
                    />
            </Stack>
        {props.rule.outOfOrderRange ? (<Typography color={'error'}>Start must be less than end.</Typography>
            ) : null}
    </>);
}

export function ViewRule(props: { rule: RuleData, onUpdate: (SegmentRuleData) => void, excludedAttributes?: RuleAttribute[] }) {
    const handleAttributeChange = (e) => {
        props.onUpdate({
            ...props.rule,
            attribute: e.target.value as RuleAttribute,
            operator: undefined,
            multiStringOperand: undefined,
            caseInsensitive: false,
            stringOperand: "",
            missingAttribute: false,
            missingOperator: false,
            emptyMultiString: false,
            outOfOrderRange: false,
            numericRangeStart: undefined,
            numericRangeEnd: undefined,
        } as RuleData);
    };
    const handleOperatorChange = (e) => {
        props.onUpdate({
            ...props.rule,
            operator: e.target.value as RuleOperator,
            missingOperator: false,
        });
    };
    const excludedAttributes = props.excludedAttributes || [];

    const attributeSelect = (
        <div>
            <FormControl variant="standard">
                <InputLabel>Attribute</InputLabel>
                <Select
                    displayEmpty
                    onChange={handleAttributeChange}
                    value={props.rule.attribute || ""}
                    error={props.rule.missingAttribute}
                >
                    {
                        Object.entries(ruleAttributeLabel).map(([key, value]) =>
                            excludedAttributes.indexOf(key as RuleAttribute) === -1 &&
                                    <MenuItem key={key} value={key}>{value}</MenuItem>
                        )
                    }
                </Select>
                {props.rule.missingAttribute ? <FormHelperText>Missing</FormHelperText> : null}
            </FormControl>
        </div>
    );
    const allowedOperators = props.rule.attribute ? getOperatorOptionsForAttribute(props.rule.attribute) : [];
    const realOperator = props.rule.operator && allowedOperators.includes(props.rule.operator) ? props.rule.operator : undefined;
    const operators = props.rule.attribute && allowedOperators.length > 0 ? (
        <div>
            <FormControl variant="standard">
                <InputLabel>Operator</InputLabel>
                <Select
                    displayEmpty
                    value={realOperator || ''}
                    onChange={handleOperatorChange}
                    error={props.rule.missingOperator}
                >
                    {
                        allowedOperators.map((operator) =>
                            <MenuItem key={operator} value={operator}>{operatorLabels[operator]}</MenuItem>)
                    }
                </Select>
                {props.rule.missingOperator ? <FormHelperText>Missing</FormHelperText> : null}
            </FormControl>
        </div>
    ) : null;

    const numericRangeOperand = realOperator === RuleOperator.NumericRange ? (
        <NumericRangeOperand {...props}/>
    ) : null;
    const chipOperand = realOperator && realOperator in operatorsWithChips ? (
        <ChipOperand {...props}/>
    ) : null;
    const checkBoxOperand = realOperator && realOperator in operatorsWithCheckbox ? (
        <CheckboxOperand {...props} />
    ) : null;
    const textOperand = realOperator && realOperator in operatorsWithString ? (
        <TextOperand {...props} />
    ) : null;
    const percentageRolloutOperand = props.rule.attribute === RuleAttribute.PercentageRollout ? (
        <PercentageRolloutOperand {...props} />
    ) : null;
    const userSegmentsOperand = props.rule.attribute === RuleAttribute.UserSegments && realOperator === RuleOperator.BelongsToAny ? (
        <UserSegmentOperand {...props} />
    ) : null;

    return (
        <Stack spacing={1}>
            {attributeSelect}
            {operators}
            {percentageRolloutOperand}
            {numericRangeOperand}
            {chipOperand}
            {textOperand}
            {userSegmentsOperand}
            {checkBoxOperand}
        </Stack>
    );
}